import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';

import ERROR_MESSAGE from 'constants/errorMessage';
import auth from 'utils/auth';
import { getRefreshTokenConfigFn as generateRefreshTokenConfig } from 'config/refreshToken/exodus';
import credentials from 'lib/entities/credentials';
import handleErrorMessageAPI from 'global/AlertErrorMessage';
import verifiedBadgeApi from 'lib/api/verifiedBadge';
import { clevertapProfilingUser } from 'utils/clevertap';
import { registerTypes } from 'features/register/constants';

import noticeError from 'utils/logger';
import {
  submitEmailLogin,
  submitFacebookLogin,
  submitGoogleLogin,
  sendClevertapEvent,
  formatErrorMessage,
} from '../helpers';
import { ErrorResponse, LoginData } from '../types';
import { useLoginAction } from '../store';

import { IS_LOGGED_IN_LOCALSTORAGE } from '../constants';

async function handleSuccess(data: LoginData, loginType: string) {
  sendClevertapEvent.loginSuccess(loginType);

  const { id: userId, username, email, avatar } = data.user;

  const responseVerified = await verifiedBadgeApi.getVerifiedBadgeUser(userId);

  if (responseVerified?.data) {
    const isEmitenUser = responseVerified.data.is_emitten || false;

    // Track if username is empty
    if (!username) {
      noticeError(new Error('[CT initialization Error]: username is empty'), {
        place: 'src/features/login/hooks/useLoginMutation.ts',
        event: 'validatePhoneOtp',
        eventParam: { profile: data.user },
      });
    }

    // @ts-ignore
    clevertapProfilingUser({
      email,
      identity: username,
      isEmitenUser,
      isInitProfile: true,
      profilePictureUrl: avatar,
    });
  }

  localStorage.setItem(IS_LOGGED_IN_LOCALSTORAGE, 'true');

  const { support } = data;
  if (support) {
    auth.setLocalCrispSettings({ ...support });
  }
}

function handleError(loginType: string, errorMessage: string) {
  sendClevertapEvent.loginFailed(loginType, errorMessage);
}

function useLoginEmailMutation() {
  const dispatch = useDispatch();
  const { stopLoading, setError } = useLoginAction();
  return useMutation({
    mutationFn: submitEmailLogin,
    onSuccess: async ({ data }) => {
      dispatch(credentials.actions.loadCredentialsExodus(data));

      handleSuccess(data, registerTypes.EMAIL);
      generateRefreshTokenConfig(true);
      stopLoading();

      return Promise.resolve(data);
    },
    onError: (error) => {
      const errorMessage = formatErrorMessage(error);
      const error503 = (error as ErrorResponse)?.response?.status === 503;

      if (errorMessage) {
        handleError(registerTypes.EMAIL, errorMessage);
        setError(errorMessage);
      }

      if (errorMessage && !error503) {
        handleErrorMessageAPI(errorMessage, ERROR_MESSAGE.ALERT_RED);
      }

      stopLoading();

      return Promise.reject(errorMessage);
    },
  });
}

function useLoginFacebookMutation() {
  const dispatch = useDispatch();
  const { stopLoading } = useLoginAction();
  return useMutation({
    mutationFn: submitFacebookLogin,
    onSuccess: async ({ data }) => {
      dispatch(credentials.actions.loadCredentialsExodus(data));

      handleSuccess(data, registerTypes.FACEBOOK);
      generateRefreshTokenConfig(true);
      stopLoading();

      return Promise.resolve(data);
    },
    onError: (error, { resolveLoginErrorCallback }) => {
      const errorMessage = formatErrorMessage(error);

      if (errorMessage) {
        handleError(registerTypes.FACEBOOK, errorMessage);
        handleErrorMessageAPI(errorMessage, ERROR_MESSAGE.ALERT_RED);
      }

      if (resolveLoginErrorCallback) resolveLoginErrorCallback();

      stopLoading();

      return Promise.reject(errorMessage);
    },
  });
}

function useLoginGoogleMutation() {
  const dispatch = useDispatch();
  const { stopLoading } = useLoginAction();
  return useMutation({
    mutationFn: submitGoogleLogin,
    onSuccess: async ({ data }) => {
      dispatch(credentials.actions.loadCredentialsExodus(data));

      handleSuccess(data, registerTypes.GOOGLE);
      generateRefreshTokenConfig(true);
      stopLoading();

      return Promise.resolve(data);
    },
    onError: (error, { resolveLoginErrorCallback }) => {
      const errorMessage = formatErrorMessage(error);

      if (errorMessage) {
        handleError(registerTypes.GOOGLE, errorMessage);
        handleErrorMessageAPI(errorMessage, ERROR_MESSAGE.ALERT_RED);
      }

      if (resolveLoginErrorCallback) resolveLoginErrorCallback();

      return Promise.reject(errorMessage);
    },
  });
}

export {
  useLoginEmailMutation,
  useLoginFacebookMutation,
  useLoginGoogleMutation,
};
