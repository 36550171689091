import Text from '@deprecated-ui/common/Text';
import color from '@deprecated-ui/_style/color';
import { DividerContainer, Center } from './styled';

interface DividerProps {
  className?: string;
  label: string;
}

function Divider({ className = '', label }: DividerProps) {
  return (
    <DividerContainer className={className}>
      <Center>
        <Text align="center" color={color.midgray}>
          {label}
        </Text>
      </Center>
    </DividerContainer>
  );
}

export default Divider;
