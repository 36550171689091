import GoogleLogin, { GoogleLoginResponse } from 'react-google-login';
import Button from '@deprecated-ui/common/Button';
import AuthButtonView from 'features/register/AuthButton';
import { registerTypes } from 'features/register/constants';
import useErrorHandler from '../hooks/useErrorHandler';
import { useLoginState, useLoginAction } from '../store';
import { sendClevertapEvent } from '../helpers';
import { useLoginGoogleMutation } from '../hooks/useLoginMutation';
import { DATA_CY_LOGIN_COMPONENTS as DATA_CY } from '../__tests__/constants';

const googleClientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;

function GoogleOauth() {
  const loginMutation = useLoginGoogleMutation();
  const { errorHandler } = useErrorHandler();
  const { isLoading } = useLoginState();
  const { startLoading, setError } = useLoginAction();

  const handleCallback = {
    success: (response: GoogleLoginResponse) => {
      if (!response) return;

      startLoading();

      const {
        profileObj: { email, name: fullname },
        googleId,
        tokenId,
      } = response;

      const params = {
        accountId: googleId,
        accessToken: tokenId,
      };

      loginMutation.mutate({
        ...params,
        resolveLoginErrorCallback: () =>
          errorHandler({
            accountid: googleId,
            accountkey: tokenId,
            email,
            fullname,
            type: registerTypes.GOOGLE,
          }),
      });
    },
    error: (err: { error: string }) => {
      setError(err.error);
    },
  };

  return (
    <GoogleLogin
      clientId={googleClientId}
      buttonText="Login"
      onSuccess={handleCallback.success}
      onFailure={handleCallback.error}
      cookiePolicy="single_host_origin"
      className="register-with-google-button hide"
      render={({ onClick }) => (
        <Button
          height="44px"
          // @ts-expect-error
          mt="10px"
          onClick={() => {
            sendClevertapEvent.loginMethod(registerTypes.GOOGLE);
            onClick();
          }}
          block
          loading={isLoading}
          id="google-login-button"
          data-cy={DATA_CY.LOGIN_FORM_BUTTON_GOOGLE}
        >
          <AuthButtonView
            iconUrl="/icon/register/icon-google.svg"
            text="Login with Google"
            isFilled
          />
        </Button>
      )}
    />
  );
}

export default GoogleOauth;
