import Text from '@deprecated-ui/common/Text';
import InputDefault from '@deprecated-ui/common/Input';
import device from '@deprecated-ui/_style/device';
import color from '@deprecated-ui/_style/color';
import styled, { createGlobalStyle, css } from 'styled-components';

const Wrapper = styled.div`
  box-sizing: border-box;
  background-color: ${color.light};
  border: 1px solid ${color.light};
  border-radius: 4px;
  box-shadow: 0 0 10px ${color.lowgray};

  @media ${device.mobileS} {
    width: 100%;
    padding: 20px 40px;
  }

  @media ${device.tablet} {
    width: 450px;
    padding: 40px 70px;
  }
`;

const InputPhoneContainer = styled.div`
  position: relative;

  .phone-dropdown-button {
    background-color: transparent !important;
    border: none;

    div {
      border: none;
    }

    .arrow {
      left: 24px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 5px solid #555;
    }
  }

  .phone-input {
    font-size: 15px !important;
    line-height: 19px;
    font-weight: 700 !important;
    color: #333333 !important;
  }

  .phone-input::placeholder {
    font-weight: 400 !important;
  }

  button {
    background: transparent;
    border: none;
    position: absolute;
    right: 0;
    top: 20%;
    cursor: pointer;
  }

  img {
    width: 16px;
    height: 16px;
  }
`;

const Input = styled(InputDefault)`
  font-size: 20px;
  padding: 0;
`;

const InputContainer = styled.div`
  width: 30px;
  margin-right: 10px;
  overflow: hidden;
`;

const InputNumber = styled(InputDefault)`
  border: none;
  border-bottom: 1px solid ${color.primary};
  width: 45px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 0;

  :focus,
  :hover {
    border-bottom: 1px solid ${color.primary};
  }
`;

const TextInfo = styled(Text)`
  font-size: 12px;
  color: ${color.dimGray};
  text-align: center;
`;

const CustomStyle = createGlobalStyle`
  /* Hide google recaptcha */
    .grecaptcha-badge {
      opacity: 1;
      visibility: visible;
    }
`;

interface ISpacer {
  height?: number;
}

const Spacer = styled.div<ISpacer>`
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  align-self: stretch;
  width: 100%;
`;

const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const InputPinContainer = styled.div`
  margin-top: -20px;
`;

const RegisterStyle = createGlobalStyle`
  .register,
  .login {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border: none;

    button.ant-btn {
      border-radius: 4px;
      padding: 5px 16px;
    }

    button.ant-btn-facebook {
      background: #33579D;
    }

    button.btn-successful-register {
      border-radius: 4px;
    }

    button.ant-btn-default {
      border: 1px solid ${color.downgray};
    }

    button.ant-btn .ant-btn-loading-icon {
      display: flex;
    }

    button:disabled, button:disabled:hover {
      background-color: #8CD9BC !important;
      border-color: #8CD9BC !important;

      p {
        color: ${color.light} !important;
      }
    }

    .form-divider {
      margin: 10px 20px;
      width: auto;
      
      p {
        font-family: ProximaNovaLt;
      }
    }

    @media ${device.mobileS} {
      width: 100% !important;
      padding: 28px 30px !important;
    }

    @media ${device.mobileL} {
      width: 450px !important;
      padding: 32px 40px !important;
    }
  }

  .auth-text-container {
    padding: 0;
  }

  .ant-message-notice.sysmsg-content {
    max-width: 440px !important;
  }

  @media ${device.mobileS} {
    .ant-tooltip-placement-leftTop {
      display: none;
    }

    .ant-tooltip-hidden {
      display: none !important;
    }
  }

  @media ${device.laptop} {
    .ant-tooltip-placement-leftTop {
      display: block;
    }

    .ant-tooltip-hidden {
      display: none !important;
    }
  }
`;

const InputPinWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 24px 0 20px;

  input {
    border: none;
    border-bottom: 1px solid ${color.highgray};
    width: 35px;
    height: 40px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    outline: none;
  }

  input:focus,
  input.filled {
    border-color: ${color.primary};
  }
`;

export {
  ButtonContainer,
  ButtonIcon,
  CustomStyle,
  Input,
  InputContainer,
  InputNumber,
  InputPhoneContainer,
  InputPinContainer,
  InputPinWrapper,
  RegisterStyle,
  Spacer,
  TextInfo,
  Wrapper,
};
