import Router, { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import useMobileView from 'hooks/useMobileView';
import { selectors } from 'lib/entities/credentials';
import { sanitizeURL } from 'utils/general';

import { useLoginState, useLoginAction } from '../store';

import { LS_AUTH_KEY, LS_REDIRECTION_TO } from '../constants';

function useLoginRedirection() {
  const dispatch = useDispatch();
  const router = useRouter();

  const { isLoggedIn } = useSelector(selectors);
  const { isLoading } = useLoginState();
  const { stopLoading } = useLoginAction();
  const { isMobileView } = useMobileView();

  const [authenticating, setAuthenticating] = useState(false);
  const [navigating, setNavigating] = useState(false);

  useEffect(() => {
    setAuthenticating(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isLoggedIn && localStorage.getItem(LS_AUTH_KEY)) {
      setNavigating(true);

      const redirectQueryParam = router.query?.redirect?.toString() || '';
      const lsRedirectionTo = localStorage.getItem(LS_REDIRECTION_TO);
      const redirectUrl = redirectQueryParam || lsRedirectionTo || '/stream';
      const destination = decodeURIComponent(redirectUrl);

      router.push(sanitizeURL(destination));

      if (lsRedirectionTo) {
        localStorage.removeItem(LS_REDIRECTION_TO);
      }
    }

    const handleStopLoading = () => {
      setAuthenticating(false);
      stopLoading();
    };

    const handleRouteChangeStart = () => {
      handleStopLoading();
    };

    window.addEventListener('beforeunload', handleStopLoading);
    Router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      setNavigating(false);
      Router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [isLoggedIn, router, isMobileView, dispatch]);

  return { authenticating, navigating };
}

export default useLoginRedirection;
