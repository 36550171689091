import styled, { createGlobalStyle } from 'styled-components';
import IconBase64 from '@deprecated-ui/common/IconBase64';
import InputAntd from '@deprecated-ui/common/Input';
import InputAntdContainer from '@deprecated-ui/common/InputForm';
import device from '@deprecated-ui/_style/device';
import color from '@deprecated-ui/_style/color';

const Wrapper = styled.div`
  box-sizing: border-box;
  background-color: ${color.light};
  border: 1px solid ${color.light};
  border-radius: 4px;
  box-shadow: ${color.gainsboro} 0px 0px 10px;
  margin: auto;

  @media ${device.mobileS} {
    width: 100%;
    padding: 20px 40px;
  }

  @media ${device.mobileL} {
    width: 450px;
    padding: 40px 70px;
  }
`;

const Input = styled(InputAntd).attrs({ p: '0' })`
  font-size: 20px;
  width: 100% !important;
  font-family: 'ProximaNovaLt';

  &#username {
    width: 100%;
  }

  &#password {
    width: calc(100% - 28px) !important;
  }

  input {
    &::placeholder {
      font-family: 'ProximaNovaLt';
    }
  }
`;

const PasswordContainer = styled.div`
  position: relative;

  label {
    font-family: 'ProximaNovaLt';
  }

  button {
    background: transparent;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  img {
    width: 16px;
    height: 16px;
  }
`;

const EmailContainer = styled(PasswordContainer)<{ showIcon?: boolean }>`
  ${({ showIcon = false }) =>
    showIcon &&
    `
    ${Input} {
      width: calc(100% - 28px) !important;
    }
  `}

  img {
    width: 18px;
    height: 18px;
  }
`;

const DividerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: ${color.gainsboro};
`;

const Center = styled.div`
  padding: 0 20px;
  background-color: ${color.light};
`;

const ImgIcon = styled(IconBase64)`
  border-radius: 0;
  width: 14px;
  height: 14px;
`;

const InputContainer = styled(InputAntdContainer)`
  label {
    font-family: 'ProximaNovaLt';
  }

  input {
    color: ${color.highempahis};
    font-size: 18px;
  }

  input:not(:placeholder-shown) ~ label,
  input:focus ~ label {
    font-family: 'ProximaNovaBd';
    font-size: 12px !important;
    top: -24px !important;
    color: ${color.highempahis};
  }
`;

const LoginStyle = createGlobalStyle`
  .login,
  .register {
    border: none;
    display: flex;
    flex-direction: column;
    gap: 24px;

    button.ant-btn {
      border-radius: 4px;
      padding: 5px 16px;
    }

    button.ant-btn-facebook {
      background: #33579D;
    }

    button.btn-successful-register {
      border-radius: 4px;
    }

    button.ant-btn-default {
      border: 1px solid ${color.downgray};
    }

    button.ant-btn .ant-btn-loading-icon {
      display: flex;
    }

    button:disabled, button:disabled:hover {
      background-color: #8CD9BC !important;
      border-color: #8CD9BC !important;

      p {
        color: ${color.light} !important;
      }
    }

    .form-divider {
      margin: 10px 20px;
      width: auto;
      
      p {
        font-family: ProximaNovaLt;
      }
    }

    @media ${device.mobileS} {
      padding: 28px 30px !important;
      width: 100% !important;
    }

    @media ${device.mobileL} {
      padding: 32px 40px !important;
      width: 450px !important;
    }
  }

  .auth-text-container {
    padding: 0;
  }

  .ant-message-notice.sysmsg-content {
    max-width: 440px !important;
  }
`;

export {
  Wrapper,
  Input,
  DividerContainer,
  Center,
  PasswordContainer,
  EmailContainer,
  ImgIcon,
  InputContainer,
  LoginStyle,
};
