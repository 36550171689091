import { useState, useEffect } from 'react';

function useMobileView() {
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const checkWindowWidth = () => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      const { clientWidth: width } = document?.documentElement || {};

      if (width < 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }, 16);
  };

  useEffect(() => {
    window.addEventListener('resize', checkWindowWidth);

    checkWindowWidth();

    return () => {
      window.removeEventListener('resize', checkWindowWidth);
    };
  }, []);

  return { isMobileView };
}

export default useMobileView;
