import Text from '@deprecated-ui/common/Text';
import color from '@deprecated-ui/_style/color';
import Image from 'global/Image';
import { ButtonContainer, ButtonIcon } from '../styled';

interface AuthButtonProps {
  iconUrl: string;
  text: string;
  isFilled?: boolean;
}

export default function AuthButtonView({
  iconUrl,
  text,
  isFilled = false,
}: AuthButtonProps) {
  const textColor = isFilled ? color.black : color.light;
  return (
    <ButtonContainer>
      <ButtonIcon>
        <Image src={iconUrl} width={24} height={24} />
      </ButtonIcon>
      <Text color={textColor} size="14px" family="bold">
        {text}
      </Text>
    </ButtonContainer>
  );
}
