export const TEST_CASE_LOGIN_COMPONENTS = (comp: string) => ({
  SHOULD_RENDER_SUCCESSFULLY: `Component ${comp} in Login Feature should render successfully`,
});

export const DATA_CY_LOGIN_COMPONENTS = {
  LOGIN_FORGOT_PW_LINK: 'auth-card-forgot-pw-link',
  LOGIN_LAYOUT: 'auth-card-layout',
  LOGIN_SIGNUP_LINK: 'auth-card-register-link',
  LOGIN_TERMS_LINK: 'auth-card-terms-link',
  LOGIN_SPINNER: 'global-loading-spinner-container',
  LOGIN_ALERT: 'global-alert-message',

  LOGIN_FORM: 'login-form',
  LOGIN_FORM_USERNAME: 'login-form-username',
  LOGIN_FORM_USERNAME_ICON: 'login-form-username-icon',
  LOGIN_FORM_PW: 'login-form-pw',
  LOGIN_FORM_PW_SHOW: 'login-form-show-pw',
  LOGIN_FORM_SUBMIT: 'login-form-submit',
  LOGIN_FORM_BUTTON_GOOGLE: 'login-form-button-facebook',
  LOGIN_FORM_BUTTON_FACEBOOK: 'login-form-button-google',
};
