import styled from 'styled-components';
import Flex from '@deprecated-ui/common/Flex';
import Text from '@deprecated-ui/common/Text';
import If from 'global/ErrorHandlers/If';

interface SecondarySpinnerProps {
  size?: string;
  text?: string;
  color?: string;
  isLoading?: boolean;
  style?: React.CSSProperties;
}

// eslint-disable-next-line space-infix-ops
const SecondarySpinnerDiv = styled.div<SecondarySpinnerProps>`
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
      opacity: 1;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      transform: rotate(360deg);
      opacity: 1;
    }
  }

  > div {
    display: block;
    margin: auto;
    height: ${(props) => props?.size || '18px'};
    width: ${(props) => props?.size || '18px'};
    animation: rotate 0.8s infinite linear;
    border-radius: 50%;
    border: solid 1.5px ${(props) => props?.color || '#6b6b6b'};
    border-right-color: transparent;
  }
`;

const SecondarySpinner = ({
  size = '18px',
  text,
  color = '#6b6b6b',
  isLoading = true,
  ...props
}: SecondarySpinnerProps) => (
  <If condition={isLoading}>
    <Flex
      direction="column"
      justify="center"
      align="center"
      {...props}
      data-cy="global-loading-spinner-container"
    >
      <SecondarySpinnerDiv size={size} color={color}>
        <div />
      </SecondarySpinnerDiv>
      <If condition={text}>
        <Text family="light" padding="20px 0 0 0">
          {text}
        </Text>
      </If>
    </Flex>
  </If>
);

export default SecondarySpinner;
