import FacebookLogin, {
  LoginResponse,
} from '@greatsumini/react-facebook-login';
import Button from '@deprecated-ui/common/Button';
import AuthButtonView from 'features/register/AuthButton';
import { registerTypes } from 'features/register/constants';
import useErrorHandler from '../hooks/useErrorHandler';
import { useLoginState, useLoginAction } from '../store';
import { sendClevertapEvent } from '../helpers';
import { useLoginFacebookMutation } from '../hooks/useLoginMutation';
import { DATA_CY_LOGIN_COMPONENTS as DATA_CY } from '../__tests__/constants';

const fbAppId = process.env.NEXT_PUBLIC_FACEBOOK_APPID;

interface profileSuccessProps {
  id: string;
  email: string;
  name: string;
}

function FacebookOauth() {
  const loginMutation = useLoginFacebookMutation();
  const { errorHandler } = useErrorHandler();
  const { isLoading } = useLoginState();
  const { startLoading, setError } = useLoginAction();

  const handleCallback = {
    accessToken: '',
    success: (profile: LoginResponse['authResponse']) => {
      if (profile.accessToken) {
        handleCallback.accessToken = profile.accessToken;
      }
    },
    profileSuccess: (profile: profileSuccessProps) => {
      if (!profile) return;

      startLoading();

      const { id, email, name } = profile;
      const { accessToken } = handleCallback;

      if (accessToken !== '') {
        const params = {
          accountId: id,
          accessToken,
        };
        loginMutation.mutate({
          ...params,
          resolveLoginErrorCallback: () =>
            errorHandler({
              accountid: id,
              accountkey: accessToken,
              email,
              fullname: name,
              type: registerTypes.FACEBOOK,
            }),
        });
      }
    },
    error: (err: { status: string }) => {
      setError(err.status);
    },
  };

  return (
    <FacebookLogin
      appId={fbAppId}
      fields="name,email,picture"
      onSuccess={handleCallback.success}
      onProfileSuccess={handleCallback.profileSuccess}
      onFail={handleCallback.error}
      initParams={{
        version: 'v16.0',
        xfbml: true,
      }}
      render={({ onClick }) => (
        <Button
          onClick={() => {
            sendClevertapEvent.loginMethod(registerTypes.FACEBOOK);
            onClick();
          }}
          type="facebook"
          height="44px"
          block
          loading={isLoading}
          id="facebook-login-button"
          data-cy={DATA_CY.LOGIN_FORM_BUTTON_FACEBOOK}
        >
          <AuthButtonView
            iconUrl="/icon/register/icon-facebook.svg"
            text="Login with Facebook"
          />
        </Button>
      )}
    />
  );
}

export default FacebookOauth;
