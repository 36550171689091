import create from 'zustand';
import produce, { Draft } from 'immer';

import { TError, State, Actions } from './types';

const initialState: State = {
  isLoading: false,
  error: null,
  message: '',
};

type LoginStore = State & { actions: Actions };

const useStore = create<LoginStore>((set) => ({
  ...initialState,
  actions: {
    startLoading: () => {
      set(
        produce((state: Draft<State>) => {
          state.isLoading = true;
        }),
      );
    },
    stopLoading: () => {
      set(
        produce((state: Draft<State>) => {
          state.isLoading = false;
        }),
      );
    },
    setError: (error: TError) => {
      set(
        produce((state: Draft<State>) => {
          state.error = error;
          state.message = error as string;
        }),
      );
    },
  },
}));

export const useLoginAction = () => useStore((state) => state.actions);
export const useLoginState = () => useStore((state) => state);

export default useStore;
