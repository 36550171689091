import AuthCardLayout from 'global/AuthCardLayout';
import Divider from 'global/AuthCardLayout/Divider';
import QueryProvider from 'global/QueryProvider';
import SecondarySpinner from 'global/Loading/SecondarySpinner';

import EmailLoginForm from './EmailLogin';
import FacebookOauth from './FacebookOauth';
import GoogleOauth from './GoogleOauth';

import useRedirection from './hooks/useRedirection';

import { LoginStyle } from './styled';

function Login(): JSX.Element {
  const { authenticating, navigating } = useRedirection();

  const showLoginForm = !authenticating && !navigating;

  return (
    <QueryProvider>
      <AuthCardLayout withRegisterLink={showLoginForm} wrapperClassName="login">
        <LoginStyle />
        {showLoginForm ? (
          <>
            <GoogleOauth />
            <FacebookOauth />
            <Divider label="Or" className="form-divider" />
            <EmailLoginForm />
          </>
        ) : (
          <SecondarySpinner
            text="Authenticating..."
            style={{ height: '250px' }}
          />
        )}
      </AuthCardLayout>
    </QueryProvider>
  );
}

export default Login;
