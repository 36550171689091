import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import authAPI from 'lib/api/auth';
import generateUsername from 'utils/generateUsername';

import register from 'features/register/slice';

import {
  formTypes,
  mappingNewRegisterTypes,
} from 'features/register/constants';

import { ErrorHandlerProps } from '../types';

const useErrorHandler = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  const errorHandler = useCallback(
    async ({
      accountid,
      accountkey,
      email,
      fullname,
      type,
    }: ErrorHandlerProps) => {
      const account = {
        accountid,
        accountkey,
        email,
        fullname,
        type,
      };

      try {
        const { data } = await authAPI.validateSocialMedia(
          accountid,
          accountkey,
          email,
          mappingNewRegisterTypes[type],
        );

        let username = generateUsername.normalUsername(fullname);

        await authAPI
          .validateRegisterForm('username', username, data.data.key)
          .catch(() => {
            username = generateUsername.randomUsername(fullname);
          });

        const dataAccount = {
          ...account,
          username,
        };

        dispatch(
          register.actions.storeEmailVerifData({
            tempKey: data.data.key,
            emailStatus: data.data.status,
          }),
        );
        dispatch(register.actions.storeSocialIdentities(dataAccount));
        dispatch(register.actions.changeForm({ target: formTypes.EMAIL }));

        router.push('/register');
      } catch (err) {
        const username = generateUsername.randomUsername(fullname);

        const dataAccount = {
          ...account,
          username,
          type: mappingNewRegisterTypes[type],
          error: err?.cause?.message,
        };

        dispatch(register.actions.storeSocialIdentities(dataAccount));
        dispatch(register.actions.changeForm({ target: formTypes.EMAIL }));

        router.push('/register');
      }
    },
    [dispatch, router],
  );

  return { errorHandler };
};

export default useErrorHandler;
